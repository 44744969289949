
.locked-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    z-index: 1;
  }
  
  .upgrade-button {
    background-color: gold;
    border-color: gold;
    color: black;
    font-weight: bold;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    z-index: 2;
  }
  .upgrade-button:hover {
    background-color: #ffcc00;
    border-color: #ffcc00;
    color: black;
  }
  
  .content-blur {
    filter: blur(2px);
  }
  