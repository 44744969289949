/* CreateChatbot.css */
.sources-column {
    /* border-right: 1px solid #ddd; */
  }
  
  .input-column {
    padding: 20px;
    border: 2px solid #ddd;
    min-height: 300px;
  }
  
  .character-counter {
    font-size: 0.9em;
    text-align: center;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 5px;
  }
  
  .attached-files-header{
    margin-top: 20px;

  }
