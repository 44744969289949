/* ChatbotList.css */

.dashboard {
  margin-top: 20px;
}

.dashboard-title {
  margin-bottom: 30px;
}

.chatbot-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

.chatbot-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  width: 350px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chatbot-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  
}

.chatbot-thumbnail {
  width: 100%;
  height: 80px;
  align-self: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  display:flex;
  justify-content: center;
  align-items: center;
  color:#6666ff;
}
.directory-display{
  text-decoration: none;
}

.chatbot-details {
  flex-grow: 1;
  margin-top: 10px;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.card-text {
  font-size: 16px;
  color: #666;
}

.no-chatbots {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  color: #888;
}

/* .create-chatbot-btn{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color:white;
  border-radius: 5px;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: 800;
} */
.create-chatbot-btn {
  background-color: #3333CC; /* Dark black background */
  color: white; /* White text */
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 800;
  text-decoration: none;
  display: inline-block; /* Align the button properly */
  transition: background-color 0.3s ease; /* Smooth background color transition on hover */
}

.create-chatbot-btn:hover {
  background-color: #9999FF; /* Light black background on hover */
}