.hero-section {
  margin: auto;
}

.login-container {
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.login-container h2 {
  text-align: left;
  margin-bottom: 20px;
  font-size: 90px;
}

.login-container button {
  height: 48px;
  border-radius: 20px;
  border-style: none;
  margin-right: 200px;
  background-color: white !important;
  font-size: 16px;
  text-align: center;
  color: black !important;
  border: 4px solid #6370fe !important;
  font-weight: 800 !important;
}

@media screen and (min-width: 580px) {
  .login-container {
    width: 80%;
    margin: auto;
    margin-top: 50px;
    padding: 20px;
  }
}
