/* CreateChatbot.css */
.sources-column {
  /* border-right: 1px solid #ddd; */
}

.input-column {
  padding: 20px;
  border: 2px solid #ddd;
  min-height: 300px;
}

.character-counter {
  font-size: 0.9em;
  text-align: center;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 5px;
}

.progress-bar{
  background: #6666ff;
}

.use-case-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 20px;
  color: #6666FF;
}

.use-case-grid *{
  color: #6666FF;
}

.use-case-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #ddd;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
}

.use-case-button:hover, .use-case-button.selected {
  border-color: #6666FF;
  background-color: #e7e7ff;
}
.use-case-button.selected {
  border-color: #6666FF;
  background-color: #e7e7ff;
}

.use-case-title {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: #6666FF;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust the height as needed */
}

.loading-animation p {
  margin-top: 10px;
  text-align: center;
  margin-left: 10px;
  font-weight: 800;
  color: #6666FF;
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.loading-animation.fade {
  opacity: 0;
}
