.section-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 80%;
    margin: auto;
    margin-top:100px;
}

.accordion-container {
    flex: 1;
    margin-right: 20px;
}

.image-container {
    flex: 1;
    text-align: center;
}

.image-container img {
    max-width: 80%;
    height: 80%;
    border-radius: 20px;
    transition: transform 0.3s ease-in-out;
}

.section-component button {
    width: 100%;
    padding: 10px;
    background-color: #f8f9fa;
    font-size: 1.5rem;
    border: none!important;
    text-align: left;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.section-component button:hover {
    background-color: #e9ecef;
    color: black;
}

.card {
    margin-bottom: 10px;
    border:none;
}

.card-header {
    padding: 0;
    border-bottom: 2px solid #f8f9fa;
}

.card-body {
    padding: 15px;
}
