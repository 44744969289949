.card-section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 50px;
    padding: 20px;
    width: 80%;
    margin: auto;
}

.card-section .card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
}

.card-section .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-section .card img {
    width: 100%;
    height: auto;
    object-fit: cover;
    padding: 16px;
    border-radius: 20px!important;
}

.card-section .card .content {
    padding: 16px;
    flex: 1;
}
