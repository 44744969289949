.footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    font-size: 14px;
    bottom: 0;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px;
}

.footer-section {
    margin: 0 20px;
}

.footer-section h4 {
    margin-bottom: 10px;
    font-size: 16px;
}

.footer-bottom {
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #e0e0e0;
    margin-top: 20px;
}
