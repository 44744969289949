.feature-section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    width: 80%;
    margin: auto;
    margin-top:100px;
}

.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-icon {
    font-size: 24px;
    padding:20px;
    margin-right: 10px;
}

.feature-text {
    flex: 1;
}

.feature-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.feature-description {
    font-size: 14px;
    color: #666;
}
