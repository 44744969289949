/* ChatbotManagement.css */
.tab-content {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
}

.nav-pills .nav-link {
  border-radius: 0;
  border-left: 3px solid transparent;
  margin-bottom: 2px;
  color: #555;
}



.nav-pills .nav-item {
  width: 100%;
}

.button-delete {
  width: 100%;
  text-align: left;
}

.nav-tabs .nav-link.active{
  border:none;
  background: none!important;
  color: black !important;
  border-radius: 0px;
  border-bottom: 3px solid #3333CC;
}
/* Additional styling for hover states and active states if necessary */
.nav-pills .nav-link:hover {
  border:none!important;
  background: none!important;
}

.nav-link {
  cursor: pointer;
}

/* Styling for the delete button */
.button-delete {
  margin-top: 20px; /* Spacing from the last nav item */
}

/* Optional: Add a hover effect to the delete button for better UX */
.button-delete:hover {
  background-color: #dc3545;
  border-color: #dc3545;
}

.tab-content-detail-title{
  font-size: 20px;
  font-weight: bold;
}

.preview-button{
  background: none;
  border:none;
  font-size: 15px;
  color: black;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.preview-button:hover{
  background: none;
  border:none;
  opacity: 0.5;
}
.preview-button:focus{
  background: none;
  border:none;
  opacity: 0.5;
}
.share-link-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.share-link {
  flex-grow: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
}

.copy-link-btn, .visit-btn {
  padding: 10px 20px;
  border-radius: 20px;
}


.embed-options {
  margin-top: 20px;
}

.embed-option {
  margin-bottom: 20px;
}

.embed-code {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.wix-logo {
  width: 50px; /* Adjust the size as needed */
  margin-bottom: 10px;
}

.directory-display *{
  text-decoration: none;
}


.general-info {
  background-color: #f8f9fa; /* Light background */
  padding: 1rem;
  border-radius: 5px;
}

.info-item {
  margin-bottom: 1rem;
}

.info-title {
  color: #6c757d; /* Lighter color for titles */
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.info-value {
  font-size: 1rem;
  font-weight: bold;
}
