.product-display {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    justify-content: space-evenly;
    padding: 20px;
  }
  
  .product {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    width: 400px;
    position: relative;
  }
  
  .plan-header {
    text-align: center;
  }
  
  .plan-features {
    text-align: left;
    padding: 10px;
    padding-bottom:100px;
  }
  
  .plan-feature {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .plan-header h3 {
    font-weight: 800;
  }
  .check-icon {
    color: green;
  }
  
  .times-icon {
    color: grey;
  }
  
  .plan-button {
    background-color: #646DFF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
  }
  
  .plan-button:hover {
    background-color: black;
  }
  
  .plan-subscribe{
    position: absolute;
    bottom: 20px;
    text-align: center;
  }


  .success-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    margin: 20px auto;

  }
  
  .success-display .product {
    width: 100%; /* Adjust the width to fit the container */
  }
  
  .success-display .description {
    text-align: center;
    padding: 20px;
  }
  
  .success-display .description h3 {
    font-weight: 600;
    color: #646DFF; /* Green color for success message */
  }
  
  .success-display form {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .success-display form #checkout-and-portal-button {
    background-color: #646DFF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none; /* Remove underline from link */
    font-weight: bold;
  }
  
  .success-display form #checkout-and-portal-button:hover {
  }
  