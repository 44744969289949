.FormCaptureContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 80%;
  margin: auto;
  flex-direction: column;
  margin-top: 100px;
}
.form-capture-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.form-capture {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
}

.form-capture-input {
  width: 100%;
  padding: 15px;
  padding-right:0px;
  font-size: 18px;
  border-radius: 25px;
  border: 2px solid #6270FD;
  outline: none;
  text-align: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-capture-button {
  height: 48px;
  border-radius: 20px;
  border-style: none;
  background-color: white !important;
  font-size: 16px;
  text-align: center;
  color: black !important;
  border: 4px solid #6370fe !important;
  font-weight: 800 !important;
}


/* .form-capture-button {
  padding: 15px 20px;
  font-size: 18px;
  border-radius: 25px;
  border: 2px solid #6270FD;
  background-color: #6270FD;
  color: #fff;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  outline: none;
  transition: all 0.3s ease-in-out;
} */

.form-capture-button:hover {
  background-color: #0056b3;
}
