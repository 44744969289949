.floating-chatbot {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
}

.chatbot-container {
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 400px;
  height: 600px;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  overflow: hidden;
}

.floating-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.floating-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .chatbot-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 100px);
    border-radius: 0;
  }
}