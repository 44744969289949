.sidebar {
  padding: 20px;
  width: 220px;
  height: 100vh; /* Full height */
  overflow-y: auto; /* Scrollable if content is tall */
  /* width: 11.5rem; */
  /* box-shadow: rgba(0, 0, 0, 0.2) 6px 0px 18px 0px; */
  z-index: 11;
  position: fixed;
  font-size: 14px;
  background: #212529;
  background: #171717;
  background: #6666ff ;
  background-color: white;
  padding:0px;
  overflow-y: auto;
}

.sidebar .nav-link {
  color: #6666ff; /* Default color */
  margin-bottom: 10px; /* Space between links */
}

.sidebar .nav-link:hover,
.sidebar .nav-link:focus {
  color: #6666ff; /* Text color for hover/focus state */
  background-color: #f2f2f2; /* Background color for hover/focus state */
  color: white;
  border-radius: 5px; /* Rounded corners for hover/focus state */
}
.sidebar a{
  text-decoration: none;
  color: #6666ff;
}

.sidebar .nav-item{
  padding-left: 20px;
  padding-right: 20px;
}

.sidebar .nav-link.active {
  font-weight: bold;
  color: #6666ff!important; /* Text color for active state */
  border-radius: 5px; /* Rounded corners for active state */
}

.main-content {
  margin-left: 220px; /* Same width as the sidebar */
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  height: 100vh;
  width: calc(100% - 220px); /* Subtract the width of the sidebar */
}

.left-sidebar-brand {
  width: 100%;
  margin: auto;
  padding-bottom:20px;
  padding-top:20px;
  background: linear-gradient(90deg, #6666ff 0%, #4CABFE 100%);
  border-bottom: 2px solid #eaecef;
}

.left-sidebar-brand *{
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
}

.main-content .page-header {
  font-size: 1.625rem;
  padding: 0px;
  background-color: inherit;
  border-bottom: none;
  font-weight: 700;
  margin-top:25px;  
}


/* Add a z-index to ensure the toggler is visible above other content */
.navbar-toggler-icon {
  z-index: 1020 !important;
  border: none!important;
  position: relative; /* Adjust as needed, could also try 'absolute' or 'fixed' */
}



/* Ensure mobile visibility respects the collapsed state */
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 75%;
    transition: left 0.3s ease;
  }
  .sidebar.collapsed {
    left: -75%; /* Hide sidebar off-screen when collapsed */
  }
  .left-sidebar-brand {
    display: none; /* Hide the brand when collapsed */
  }
  .main-content {
    width: 100%;
    margin-left: 0;
  }
}

/* Add a transition effect for the sidebar */
.sidebar {
  transition: width 0.3s;
}


.list-group-item.active{
  background-color: #343a40;
  background-color: #FAFAFA;
  color: #3333CC;
  border-color: #3333CC;
}

.nav-link{

  color: black;
  
}

.nav-link.active{
  border:none;
  background-color: #2f2f2f!important;
  color: white !important;
  border-radius: 5px;
}

.nav-tabs{
  border-bottom: none;
}
