.color-picker-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the gap between color pickers as needed */
  }
  
  .color-picker-item {
    flex: 1;
    min-width: 220px; /* Adjust the minimum width for color pickers as needed */
  }
  

  /* ChatInterfaceSubTab.css */

.watermark-section {
  position: relative;
}

.watermark-section .locked-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(.2px);
  z-index: 1;
}

.upgrade-button {
  background-color: gold;
  border-color: gold;
  color: black;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 16px;
  z-index: 2;
}


.chat-interface-container {
  display: flex;
  flex-direction: column;
}

.settings-container {
  flex: 1;
}

.chatbot-preview-container {
  flex: 1;
  height: 500px;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .chat-interface-container {
    flex-direction: row;
  }

  .settings-container {
    padding-right: 40px;
  }

  .chatbot-container {
    margin-top: 0;
    margin-left: 20px;
  }
}