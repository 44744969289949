.webhook-setup-container {
    /* max-width: 600px; */
    position: relative;
  }
  
  .webhook-setup-container h4 {
    margin-bottom: 20px;
  }
  
  .webhook-setup-container p {
    margin-bottom: 15px;
  }
  
  .webhook-setup-container .form-group {
    margin-bottom: 15px;
  }
  
  .webhook-setup-container .btn {
    margin-top: 10px;
    width: auto;
  }
  