.account-page {
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
    margin-top: 50px;
  }
  
  .account-header {
    text-align: center;
    color: #3333cc;
    font-size: 24px;
    font-weight: bold;
  }
  
  .account-content {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  .account-content h3 {
    color: #333333;
    margin-bottom: 10px;
  }
  
  .account-content p {
    color: #666666;
    margin-bottom: 20px;
  }
  
  .billing-btn {
    background-color: #6666ff;
    border: none;
    padding: 10px 20px;
    color: #ffffff;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .billing-btn:hover {
    background-color: #9999ff;
  }
  