.chat-logs-container {
    display: flex;
    gap: 20px;
    position: relative;
  }
  
  .chat-log-list {
    flex: 1;
    max-width: 40%;
  }
  
  .chat-log-card {
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .chat-history-container {
    flex: 2;
  }
  
  .chat-history {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    overflow-y: auto;
    max-height: 400px;
  }
  
  .message {
    padding: 8px 12px;
    border-radius: 20px;
    margin-bottom: 8px;
    max-width: 80%;
  }
  
  .message.assistant {
    background-color: #e0e0e0;
    align-self: flex-start;
  }
  
  .message.user {
    background-color: #007bff;
    color: #fff;
    align-self: flex-end;
  }
  