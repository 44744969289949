/* Chat.css */
.chat-container {
  display: flex;
  flex-direction: column; /* Keeps the direction of items from top to bottom */
  justify-content: space-between; /* This will push the input bar to the bottom */
  width: 100%;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
  border: 2px solid #f2f2f2;
  background: white;
  border-radius: 10px;
  margin: auto;
  overflow: hidden; /* Prevents overflow from messing up the layout */
}

.chatbot-display-name{
  color: #3F3F44;
  font-size: 20px;
  padding-top:20px;
  padding-bottom: 10px;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid #f2f2f2;
  width: calc(100% - 40px);
  margin: auto;
}

@media (max-width: 768px) {

}

.message-list {
  flex: 1; /* Allows the message list to grow and fill the available space */
  overflow-y: auto; /* Keeps the overflow content scrollable */
  margin-bottom: -1px; /* Adjust if there is a gap above the input bar */
  padding: 20px;
  display: flex;
  flex-direction: column;
}

/* General message styles */
.message {
  padding: 10px 20px;
  border-radius: 20px;
  max-width: 80%; /* Ensures messages do not stretch too wide */
  margin-bottom: 12px;
  line-height: 1.4;
  display: block; /* Ensures each message is on its own line */
  width: fit-content; /* Makes the width of the message fit its content */
  word-wrap: break-word; /* Ensures long words do not overflow */
}

/* Styles for messages from the bot */
.message.assistant {
  background-color: #e0e0e0; /* Light grey background for bot messages */
  text-align: left;
  color: #4b4f56;
  border-bottom-left-radius: 0; /* Makes it look like a speech bubble */
}

/* Styles for messages from the bot's lead capture */
.message.lead-capture {
  background-color: #e0e0e0; /* Light grey background for bot messages */
  text-align: left;
  min-width: 300px;
  max-width: 50%;
  color: #4b4f56;
  padding:20px;
  border-bottom-left-radius: 0; /* Makes it look like a speech bubble */
}

/* Styles for messages from the user */
.message.user {
  background-color: #007bff; /* Blue background for user messages */
  text-align: right;
  color: #ffffff;
  border-bottom-right-radius: 0; /* Makes it look like a speech bubble */
  margin-left: auto; /* Aligns the message to the right */
}

/* Adjustments for the is-typing indicator */
.is-typing {
  text-align: center;
  color: #aaa;
  font-style: italic;
}

.is-typing {
  text-align: center;
  color: #aaa;
}

.input-bar {
  border-top: 1px solid #eee;
  background: #f9f9f9;
  padding: 10px;
  flex-direction: row;
  width: 100%;
}

.input-bar input {
  padding: 10px;
  border: 1px solid #ddd;
  margin-right: 10px;
  border-radius: 5px;
  width: 80%;

}

.input-bar input:focus {
  outline: none;
}

.input-bar button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 15%;
}

.input-bar button:hover {
  background-color: #9999FF;
}

/* Animation for the typing indicator */
@keyframes blink {
  to {
    visibility: hidden;
  }
}

.typing-animation span {
  animation: blink .60s steps(5, start) infinite;
  font-size: 60px;
  color: grey;
  height: 20px;
}

.typing-animation span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-animation span:nth-child(3) {
  animation-delay: 0.4s;
}

/* Lead Form */

.lead-form {
  position: relative;
}
.lead-form-title{
  color: #3F3F44;
  font-size: 20px;
  padding-bottom: 10px;
  text-align: left;
  font-weight: bold;
  width: 100%;
}

.lead-form .submit-button{
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  font-weight: 800;

}
.lead-form .cancel-button{
  background-color: #f9f9f9;
  color: #3F3F44;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
  width: 30px;
  opacity: 0.9;

}

.lead-form input {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
}

.message p{
  margin: 0;

}
/* Existing styles above... */

/* Responsive adjustments */
@media (max-width: 768px) {
  .input-bar {
    padding: 8px; /* Reduce padding a bit */
  }

  .input-bar input {
    padding: 8px;
    margin-right: 5px; /* Reduce margin */
    width: calc(100% - 90px); /* Adjust width to ensure input and button fit */
  }

  .input-bar button {
    padding: 8px 10px; /* Reduce button padding */
    width: 80px; /* Adjust width to content */
    flex-grow: 1; /* Allow the button to grow if there's space */
  }

  .message {
    font-size: 14px; /* Adjust font size for better readability */
  }
}

@media (max-width: 480px) {
  .message-list {
    padding: 10px; /* Further reduce padding for very small screens */
  }

  .input-bar input,
  .input-bar button {
    padding: 6px 8px; /* Even smaller padding for inputs and buttons */
  }

  .message {
    font-size: 13px; /* Slightly smaller font size for very small screens */
  }

}


