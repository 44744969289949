

.App {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  background: white;
  min-height: 100vh;
}

h1 {
  font-size: 2rem;
  color: #0d6efd; /* A nice blue, change as needed */
}

.Navbar {
  border-bottom: 1px solid #eaecef;
}

.nav-link{
  cursor: pointer;
}

.meeting-table {
  /* /* max-width: 95%; */
  margin: auto;
}

.meeting-table th, .meeting-table td {
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.meeting-table td{
  padding: 10px;
}


/* Additional styling for buttons, table, etc., as needed */

.card{
  margin-bottom:20px;
}

.container-fluid{
  padding: 0 !important;
}

.container{
  width: 100vw!important;
  max-width: 100vw!important;
  padding:20px;
}


.login-button{
  height: 48px;
  border-radius: 28px;
  border-style: none;
  margin-right:20px;
  background-color: white!important;
  font-size: 16px;
  text-align: center;
  color: black!important;
  border: 2px solid #6370FE!important;
  font-weight: 800!important;

}
.list-group-item{
  cursor: pointer;
}
.signin-btn{
  width: 100%;
  height: 48px;
  border-radius: 28px;
  border-style: none;
  background-color: white!important;
  border:2px solid white!important;
  font-family: "Lato";
  font-size: 16px;
  text-align: center;
  color: black!important;
  font-weight: 800!important;
}

.signin-btn:hover, .login-button:hover{
  opacity: 0.8;
}

.feature-icons{
  margin-top: 20px;
  margin-bottom: 20px;
}
